
import {
  Component,
  Vue
} from 'vue-property-decorator'
import ScheduleService from './services/schedule-service'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import {
  ErrorModule
} from '@/store/modules/ErrorModule'
import DateHelper from '@/mixins/date-mixins'
import Draggable from 'vuedraggable'
import Status from '@/components/Status/index.vue'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
  @Component({
    name: 'Schedule',
    components: {
      Draggable,
      Status
    }
  })

export default class Schedule extends Vue {
    protected isDrag = false
    protected job: any = {}
    protected jobs: any = []
    protected activeNames: any = []
    protected commencedJobs: any = []
    protected operationalJobs: any = []
    protected dispatchJobs: any = []
    protected completedJobs: any = []

    protected commencedJobQuery: any = null
    protected operationalJobQuery: any = null
    protected dispatchJobQuery: any = null
    protected completedJobQuery: any = null

    get filteredCommencedJobs () {
      const query = this.commencedJobQuery
      if (query === '' || query === null) return this.commencedJobs

      return this.commencedJobs.filter((job: any) => {
        for (const key in job) if (String(job[key]).toLowerCase().indexOf(query.toLowerCase()) !== -1) return true
        return false
      })
    }

    get filteredOperationalJobs () {
      const query = this.operationalJobQuery
      if (query === '' || query === null) return this.operationalJobs

      return this.operationalJobs.filter((job: any) => {
        for (const key in job) if (String(job[key]).toLowerCase().indexOf(query.toLowerCase()) !== -1) return true
        return false
      })
    }

    get filteredDispatchJobs () {
      const query = this.dispatchJobQuery
      if (query === '' || query === null) return this.dispatchJobs

      return this.dispatchJobs.filter((job: any) => {
        for (const key in job) if (String(job[key]).toLowerCase().indexOf(query.toLowerCase()) !== -1) return true
        return false
      })
    }

    get filteredCompletedJobs () {
      const query = this.completedJobQuery
      if (query === '' || query === null) return this.completedJobs

      return this.completedJobs.filter((job: any) => {
        for (const key in job) if (String(job[key]).toLowerCase().indexOf(query.toLowerCase()) !== -1) return true
        return false
      })
    }

    get date () {
      return DateHelper.today('dd/mm/yyyy')
    }

    created () {
      this.boot()
    }

    protected boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.populateCommencedJobs()
      this.populateOperationalJobs()
      this.populateDispatchJobs()
      this.populateCompletedJobs()
    }

    protected async populateCommencedJobs () {
      const reponse = await ScheduleService.getCommencedJobs()
      this.commencedJobs = reponse.data
    }

    protected async populateOperationalJobs () {
      const reponse = await ScheduleService.getOperationalJobs()
      this.operationalJobs = reponse.data
    }

    protected async populateDispatchJobs () {
      const reponse = await ScheduleService.getDispatchJobs()
      this.dispatchJobs = reponse.data
    }

    protected async populateCompletedJobs () {
      const reponse = await ScheduleService.getCompletedJobs()
      this.completedJobs = reponse.data
    }

    protected schedule ({ added }) {
      if (added) {
        const job = added.element
        const id: any = document.getElementById(job.id)
        const type = id.getAttribute('data-column')
        this.job = {
          id: job.id,
          type: type,
          created_user: AuthModule.user.fullname,
          modified_user: AuthModule.user.fullname
        }
        ScheduleService.schedule(this.job).then((response) => {
          ToastModule.message(response.data.message)
          if (type === 'attend') this.populateOperationalJobs()
          this.job = {}
        }).catch(error => {
          ErrorModule.set('There was a problem while scheduling your job, Please check...')
          return error
        })
      }
    }
}

