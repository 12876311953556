import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'

export interface ErrorState {
  error: {
    opened: boolean,
    items: any
  }

}

@Module({ dynamic: true, store, name: 'error' })
class Error extends VuexModule implements ErrorState {
  public error = {
    opened: false,
    items: {}
  }

  @Mutation
  private setErrorBag (items) {
    this.error.opened = true
    this.error.items = items
  }

  @Mutation
  private destroyErrorBag () {
    this.error.opened = false
    this.error.items = {}
  }

  @Action
  public set (items) {
    this.setErrorBag(items)
  }

  @Action
  public remove () {
    this.destroyErrorBag()
  }
}

export const ErrorModule = getModule(Error)
