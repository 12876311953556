import APIService from '@/services/api-service'
const resource = '/schedule'

class ScheduleService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getCommencedJobs () {
    return this.connector.get(`${resource}/jobs/commenced`)
  }

  public getOperationalJobs () {
    return this.connector.get(`${resource}/jobs/operational`)
  }

  public getDispatchJobs () {
    return this.connector.get(`${resource}/jobs/dispatch`)
  }

  public getCompletedJobs () {
    return this.connector.get(`${resource}/jobs/completed`)
  }

  public schedule (data: any) {
    return this.connector.post(`${resource}/update`, data)
  }
}

export default new ScheduleService()
